import commonHelper from '@/app/utility/common.helper.utility';
import Responsiblity from '../responsibility';
import AddSecGroup from './addSecurityGroup';
export default {
  name: 'SecurityGroup',
  components: {
    Responsiblity,
    AddSecGroup
  },
  watch: {
    currentPage: function() {
      this.getSecurityGroupsList();
    },
    perPage: function() {
      this.currentPage = 1;
      this.getSecurityGroupsList();
    }
  },
  data() {
    return {
      payload:null,
      unsubscribe: null,
      perPage: commonHelper.perPageRecord,
      pageOptions: commonHelper.getPageOption(),
      totalRows: null,
      currentPage: 1,
      loader: false,
      showValueSetModal: false,
      showSecGroupDtlModal: false,
      showResponsibilityModal: false,
      vsetCode: null,
      resp: {
        value: null,
        text: null
      },
      secGrp: null,
      secGrpRowDtl: null,
      secGrpData: [],
      secGrpFields: [
        {
          key: 'securitygroup_hdr_id',
          class: 'd-none'
        },
        {
          key: 'securitygroup_hdr_name',
          label: 'Security Group'
        },
        {
          key: 'user_id',
          class: 'd-none'
        },
        {
          key: 'user_name',
          label: 'Created By'
        },
        {
          key: 'creation_date'
        },
        {
          key: 'active'
        }
      ]
    };
  },
  mounted() {
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (actionName === 'add') {
          this.secGrpRowDtl = null;
          this.showSecGroupDtlModal = true;
        }
        if (actionName === 'download' && !this.showSecGroupDtlModal && !this.showResponsibilityModal) {
          this.loader = true;
          /**
           * @param(payload, 'action name', 'file name')
           */
          const downloadpayload = { ...this.payload };
          downloadpayload._limit = this.totalRows;
          this.downloadExcel.downloadData(
            downloadpayload,
            'secGroup/getSecGroupMasterList',
            'security-group',
            () => (this.loader = false)
          );
        }
      }
    });
  },
  methods: {
    getSecurityGroupsList() {
      this.payload = {
        _page: this.currentPage - 1,
        _limit: this.perPage,
        securitygroup_hdr_name: this.secGrp
      };
      this.loader = true;
      this.$store
        .dispatch('secGroup/getSecGroupMasterList', this.payload)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            this.secGrpData = response.data.data.page;
            this.totalRows = response.data.data.total_elements;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    clearSearchFilters() {
      this.secGrp = null;
      this.secGrpData = [];
      this.totalRows = null;
      this.currentPage = 1;
    },
    rowSelected(item) {
      this.secGrpRowDtl = item;
      this.showSecGroupDtlModal = true;
    },
    selectedResponsibility(item) {
      this.resp = {
        value: item.responsibility_hdr_id,
        text: item.responsibility_name
      };
      this.showHideRespModal(false);
    },
    openCloseSecGroupModal(flag) {
      this.showSecGroupDtlModal = flag;
    },
    showHideRespModal(flag) {
      this.showResponsibilityModal = flag;
    }
  },
  beforeDestroy() {
    this.unsubscribe();
  }
};
