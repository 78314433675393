import appStrings from '@/app/utility/string.utility';
import DatePicker from 'vue2-datepicker';
import { ModelSelect, BasicSelect } from 'vue-search-select';
import Responsibility from '../../responsibility';
import format from 'date-fns/format';
import store from '../../../../../store';
export default {
  name: 'SecurityGroupDetail',
  props: ['secGrpRowDtl'],
  components: {
    DatePicker,
    ModelSelect,
    BasicSelect,
    Responsibility
  },
  data() {
    return {
      unsubscribe: null,
      loader: false,
      isSuccess: false,
      showAlert: false,
      responseMsg: '',
      openOuModal: false,
      showSecurityGroupModal: false,
      showValueSetModal: false,
      showSecGroupDtlModal: false,
      showResponsibilityModal: false,
      editMode: false,
      orgType: null,
      vsetCode: null,
      securityIndex: null,
      secGrp: {
        value: null,
        text: null
      },
      resp: {
        value: null,
        text: null
      },
      creationDate: format(new Date(), 'dd-MMM-yyyy'),
      userId: null,
      userName: null,
      active: true,
      securityGrpLookupList: [
        {
          value: 'LE',
          text: 'Legal Entity'
        },
        {
          value: 'GROUP',
          text: 'GROUP'
        },
        {
          value: 'OU',
          text: 'Operating Unit'
        },
        {
          value: 'INV',
          text: 'Inventory Org'
        },
        {
          value: 'LOC',
          text: 'Location'
        },
        {
          value: 'SUBINV',
          text: 'Sub Inventory'
        },
        {
          value: 'FMSPRJ',
          text: 'FMS Project'
        },
        {
          value: 'CRMPRJ',
          text: 'CRM Project'
        },
        {
          value: 'PRJ',
          text: 'Project'
        }
      ],
      secGrpDtlData: [
        {
          securitygroup_dtl_id: 0,
          securitygroup_lookup: null,
          lookup_meaning: null,
          proj_id: null,
          entity: null,
          org_id: null,
          include_exclude: false,
          active_entity: true
        }
      ],
      secGrpDtlFields: [
        {
          key: 'securitygroup_dtl_id',
          class: 'd-none'
        },
        {
          key: 'securitygroup_lookup',
          class: 'd-none'
        },
        {
          key: 'lookup_meaning',
          label: 'Security Group Lookup'
        },
        {
          key: 'proj_id',
          class: 'd-none'
        },
        {
          key: 'org_id',
          class: 'd-none'
        },
        {
          key: 'entity'
        },
        {
          key: 'include_exclude',
          label: 'Include/Exclude'
        },
        {
          key: 'active_entity',
          label: 'Active'
        },
        {
          key: 'add'
        },
        {
          key: 'remove'
        }
      ]
    };
  },
  mounted() {
    // this.getOrganizationType();
    if (this.secGrpRowDtl) {
      this.getSecGroupDetailById(this.secGrpRowDtl);
    } else {
      this.userId = store.state.auth.userId;
      const tempName = store.state.auth.userName;
      const name = tempName.slice(tempName.lastIndexOf('-') + 1);
      this.userName = name;
      this.editMode = true;
    }
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (actionName === 'add') {
          this.resetSecGroupFields();
        }
        if (actionName === 'update') {
          this.editMode = true;
        }
        if (actionName === 'save' && this.editMode) {
          this.addEditSecGroup();
        }
      }
    });
  },
  methods: {
    getOrganizationType() {
      this.$store
        .dispatch('shared/getDataFromLookupsByLookupType', 'SECGRP')
        .then(response => {
          if (response.status === 200) {
            const results = response.data.data;
            const orgType = results.map(type => {
              return {
                value: type.lookup_code.toUpperCase(),
                text: type.meaning
              };
            });
            this.securityGrpLookupList = orgType;
          }
        });
    },
    getSecGroupDetailById(data) {
      const payload = {
        secGroupHdrId: data.securitygroup_hdr_id
      };
      this.loader = true;
      this.$store
        .dispatch('secGroup/getSecGroupDetailById', payload)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            const details = response.data.data;
            this.secGrp = {
              value: details.securitygroup_hdr_id,
              text: details.securitygroup_hdr_name
            };
            this.creationDate = details.creation_date;
            this.userName = details.user_name;
            this.userId = details.user_id;
            this.resp = {
              value: details.responsibility_hdr_id,
              text: details.responsibility_hdr_name
            };
            this.active = details.active;
            this.secGrpDtlData = details.securitygroup_details;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    addEditSecGroup() {
      const details = this.secGrpDtlData.map(key => {
        return {
          org_id: key.org_id,
          proj_id: key.proj_id,
          securitygroup_dtl_id: key.securitygroup_dtl_id,
          securitygroup_lookup: key.securitygroup_lookup,
          include_exclude: key.include_exclude,
          active_entity: key.active_entity
        };
      });
      const payload = {
        securitygroup_hdr_id: this.secGrp.value,
        securitygroup_hdr_name: this.secGrp.text,
        active: this.active,
        security_details: details ? details : null
      };
      this.loader = true;
      this.$store
        .dispatch('secGroup/addEditSecGroup', payload)
        .then(response => {
          this.loader = false;
          this.showAlert = true;
          if (response.status === 200) {
            this.isSuccess = true;
            this.editMode = false;
            this.responseMsg = response.data.message;
            const details = response.data.data;
            this.secGrp = {
              value: details.securitygroup_hdr_id,
              text: details.securitygroup_hdr_name
            };
            this.creationDate = details.creation_date;
            this.userName = details.user_name;
            this.userId = details.user_id;
            this.resp = {
              value: details.responsibility_hdr_id,
              text: details.responsibility_hdr_name
            };
            this.active = details.active;
            this.secGrpDtlData = details.securitygroup_details;
          } else {
            this.isSuccess = false;
            this.responseMsg = response.response.data.message;
          }
        })
        .catch(() => {
          this.loader = false;
          this.showAlert = true;
          this.isSuccess = false;
          this.responseMsg = appStrings.autoFailedMsg;
        });
    },
    resetSecGroupFields() {
      this.secGrp = {
        value: null,
        text: null
      };
      this.secGrpDtlData = [
        {
          securitygroup_dtl_id: 0,
          securitygroup_lookup: null,
          lookup_meaning: null,
          proj_id: null,
          entity: null,
          org_id: null,
          include_exclude: false,
          active_entity: true
        }
      ];
    },
    addNewRow() {
      if (this.editMode) {
        this.secGrpDtlData.push({
          securitygroup_dtl_id: 0,
          securitygroup_lookup: null,
          lookup_meaning: null,
          proj_id: null,
          entity: null,
          org_id: null,
          include_exclude: false,
          active_entity: true
        });
      }
    },
    removeRow(index) {
      if (this.editMode) {
        this.secGrpDtlData.splice(index, 1);
      }
    },
    selectedvalueSet(item) {
      this.secGrpDtlData[this.secGroupDtlList].org_id = null;
      if (this.vsetCode === 'FMSPRJ') {
        this.secGrpDtlData[this.secGroupDtlList].entity = item.value_code;
        this.secGrpDtlData[this.secGroupDtlList].proj_id =
          item.value_set_dtl_id;
      }
    },
    openValueSetModal(vsetCode, index) {
      this.vsetCode = vsetCode;
      this.secGroupDtlList = index;
      this.showValueSetModal = true;
      this.setTimeVsetCode = setTimeout(() => {
        const vSetData = {
          valueSetName: vsetCode === 'FMSPRJ' ? appStrings.VALUESETTYPE.FMS_PROJECT : null,
          multiFlag: null
        };
        this.eventBus.$emit('valueSetCode', vSetData);
      }, 0);
    },
    closeValueSetModal() {
      this.showValueSetModal = false;
    },
    openCloseOu(flag, orgType, index) {
      this.orgType = orgType;
      this.secGroupDtlList = index;
      this.openOuModal = flag;
    },
    selectedOrg(item) {
      this.secGrpDtlData[this.secGroupDtlList].proj_id = null;
      if (this.orgType === 'GROUP') {
        this.secGrpDtlData[this.secGroupDtlList].org_id = item.org_id;
        this.secGrpDtlData[this.secGroupDtlList].entity = item.org_name;
      } else if (this.orgType === 'LE') {
        this.secGrpDtlData[this.secGroupDtlList].org_id = item.org_id;
        this.secGrpDtlData[this.secGroupDtlList].entity = item.org_name;
      } else if (this.orgType === 'OU') {
        this.secGrpDtlData[this.secGroupDtlList].org_id = item.org_id;
        this.secGrpDtlData[this.secGroupDtlList].entity = item.org_name;
      } else if (this.orgType === 'INV') {
        this.secGrpDtlData[this.secGroupDtlList].org_id = item.org_id;
        this.secGrpDtlData[this.secGroupDtlList].entity = item.org_name;
      } else if (this.orgType === 'LOC') {
        this.secGrpDtlData[this.secGroupDtlList].org_id = item.org_id;
        this.secGrpDtlData[this.secGroupDtlList].entity = item.org_name;
      }
      this.openOuModal = false;
    },
    securityModal(flag, index) {
      this.securityIndex = index;
      this.showSecurityGroupModal = flag;
    },
    selectedSecurityGroup(item) {
      this.secGrpDtlData[this.securityIndex].lookup_meaning = item.text;
      this.secGrpDtlData[this.securityIndex].securitygroup_lookup = item.value;
      this.showSecurityGroupModal = false;
    },
    selectedResponsibility(item) {
      this.resp = {
        value: item.responsibility_hdr_id,
        text: item.responsibility_name
      };
      this.showHideRespModal(false);
    },
    showHideRespModal(flag) {
      this.showResponsibilityModal = flag;
    }
  },
  beforeDestroy() {
    this.unsubscribe();
  }
};
